<template>
  <div
    class="fixed basket-container max-w-410 w-full bg-white z-header flex flex-1 flex-col border-t border-grey500"
    :class="{
      'top-120 md:mt-2': hasMarketUsps,
      'top-80': !hasMarketUsps,
    }"
    :style="styleObj"
  >
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    offset: {
      type: Number,
      required: true,
    },
    hasMarketUsps: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    styleObj(): object {
      const rightOffset = this.offset - 220;

      if (window.innerWidth > 1372 && this.offset - 220 >= 1265) {
        return {
          '--right-set': `${205}px`,
          left: `${rightOffset}px`,
          'margin-top':
            Array.from(document.querySelectorAll('.site-message')).reduce(
              (carry, current) => {
                return carry + current.clientHeight;
              },
              0
            ) + 'px',
        };
      } else {
        return {
          '--right-set': `${window.innerWidth - this.offset + 16}px`,
          right: 0,
          'margin-top':
            Array.from(document.querySelectorAll('.site-message')).reduce(
              (carry, current) => {
                return carry + current.clientHeight;
              },
              0
            ) + 'px',
        };
      }
    },
  },
});
</script>
